import React from "react";
import styled from "styled-components";

import Footer from ".";
import FooterLink from "../FooterLink";
import FooterLinks from "../FooterLinks";
import WorkDeskIcon from "../Icons/WorkDeskIcon";

const CssReset = styled.div`
  & ul {
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }
`;

const Center = styled.div`
  text-align: center;
  margin-top: ${(props) => props.theme.spacings.medium};
  margin-bottom: ${(props) => props.theme.spacings.small};
`;

export const FooterExample: React.FC<React.PropsWithChildren<unknown>> = () => (
  <CssReset>
    <Footer>
      <FooterLinks>
        <FooterLink href="/about" text="About" />
        <FooterLink href="/imprint" text="Imprint" />
        <FooterLink href="/faq" text="FAQ" />
      </FooterLinks>
    </Footer>
  </CssReset>
);

export const FooterExample2: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <CssReset>
    <Footer>
      <FooterLinks>
        <FooterLink href="/about" text="About" />
        <FooterLink href="/imprint" text="Imprint" />
        <FooterLink href="/faq" text="FAQ" />
      </FooterLinks>
      <Center>
        <WorkDeskIcon isOnDarkBackground scale="huge" />
      </Center>
    </Footer>
  </CssReset>
);
