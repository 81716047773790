import React from "react";

import FormInputDate from "./";

type FormInputDateDemoProps = React.ComponentProps<typeof FormInputDate>;

export const FormInputDateDemo: React.FC<
  React.PropsWithChildren<FormInputDateDemoProps>
> = (props) => {
  const [value, setValue] = React.useState(props.value);

  return (
    <FormInputDate
      {...props}
      value={value}
      onChange={(event) => {
        setValue(event.target.value);

        if (props.onChange) {
          props.onChange(event);
        }
      }}
    />
  );
};
