import { isSameDay } from "date-fns";
import React from "react";

import CalendarGrid from "./CalendarGrid";
import CalendarMonthDay from "./CalendarMonthDay";
import { WeekDaysRow } from "./createMonthDaysMatrix";

type CalendarMonthWeekProps = {
  days: Readonly<WeekDaysRow>;
  currentDate: Date;
  selectedDate?: Date;
  dayId: (date: Date) => string;
  onDayClick?: React.ComponentProps<typeof CalendarMonthDay>["onClick"];
  onDayKeyUp?: React.ComponentProps<typeof CalendarMonthDay>["onKeyUp"];
};

const CalendarMonthWeek: React.FC<
  React.PropsWithChildren<CalendarMonthWeekProps>
> = ({ dayId, days, currentDate, selectedDate, onDayClick, onDayKeyUp }) => (
  <CalendarGrid.Row role="row">
    {days.map((dateEntry, dateIndex) => (
      <CalendarGrid.Column
        key={dateIndex}
        role={dateEntry ? "gridcell" : "presentation"}
      >
        {dateEntry ? (
          <CalendarMonthDay
            id={dayId(dateEntry.date)}
            selected={selectedDate && isSameDay(selectedDate, dateEntry.date)}
            focusable={isSameDay(currentDate, dateEntry.date)}
            date={dateEntry.date}
            onClick={onDayClick}
            onKeyUp={onDayKeyUp}
            isDisabled={!dateEntry.isEnabled}
          />
        ) : (
          <CalendarGrid.Content aria-hidden="true" />
        )}
      </CalendarGrid.Column>
    ))}
  </CalendarGrid.Row>
);

export default CalendarMonthWeek;
