import React from "react";
import styled from "styled-components";

import EkomiRating from "../EkomiRating";
import Layer from "../Layer";
import Logo from "../Logo";
import ResponsiveContainer from "../ResponsiveContainer";
import Spacings from "../Spacings";
import VerticalFlag, { VERTICAL_FLAG_WIDTH } from "../VerticalFlag";

import Layout from "./Layout";

const HeaderContent = styled(Spacings.Inline)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    > * + *:not(:last-child) {
      margin-left: ${(props) => props.theme.spacings.medium};
    }
  }
`;

const AccountLinkWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding-right: calc(
      ${(props) => props.theme.spacings.big} + ${VERTICAL_FLAG_WIDTH}
    );
    margin-right: ${(props) => props.theme.spacings.small};
  }
  @media (min-width: ${(props) =>
      props.theme.layout.containerWidthBreakpoint}) {
    margin-right: 0;
  }
`;

const PhoneNumberWrapper = styled.div`
  margin-left: auto;
  @media (min-width: ${(props) => props.theme.layout.mobileBreakpoint}) {
    padding-right: calc(
      ${(props) => props.theme.spacings.big} + ${VERTICAL_FLAG_WIDTH}
    );
    margin-right: auto;
  }
`;

export type HeaderProps = {
  accountLink?: React.ReactNode;
  ekomiFlag: React.ReactNode;
  ekomiImageBig: React.ReactNode;
  ekomiImageSmall: React.ReactNode;
  guidanceStepper?: React.ReactNode;
  logoWithLink?: React.ReactNode;
  phoneNumber?: React.ReactNode;
  trustBar?: React.ReactNode;
};

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  accountLink,
  ekomiFlag,
  ekomiImageBig,
  ekomiImageSmall,
  guidanceStepper,
  logoWithLink,
  phoneNumber,
  trustBar,
}) => (
  <Layout.Grid>
    {trustBar ? (
      <ResponsiveContainer showOn={["tablet", "desktop"]}>
        <Layout.TrustBarBackground />
        <Layout.TrustBar>
          <Layout.Flag>
            <VerticalFlag>
              <Spacings.Stack alignItems="center">
                {ekomiImageBig}
                {ekomiFlag}
                <EkomiRating isCondensed scale="tiny" />
              </Spacings.Stack>
            </VerticalFlag>
          </Layout.Flag>
        </Layout.TrustBar>
      </ResponsiveContainer>
    ) : null}
    <Layout.Background>
      <Layer.Raised />
    </Layout.Background>
    <Layout.Main>
      <Spacings.Inset>
        <HeaderContent>
          {logoWithLink ? (
            logoWithLink
          ) : (
            <a
              href={"/"}
              data-testid="logo"
              aria-label="Finanzch24 GmbH Homepage"
            >
              <Logo />
            </a>
          )}
          {trustBar ? (
            <ResponsiveContainer showOn={["mobile"]}>
              <Spacings.Inline alignItems="center">
                {ekomiImageSmall}
                <Spacings.Stack>
                  {ekomiFlag}
                  <EkomiRating isCondensed scale="tiny" />
                </Spacings.Stack>
              </Spacings.Inline>
            </ResponsiveContainer>
          ) : null}
          {phoneNumber && (
            <PhoneNumberWrapper>{phoneNumber}</PhoneNumberWrapper>
          )}
          {accountLink ? (
            <AccountLinkWrapper>{accountLink}</AccountLinkWrapper>
          ) : null}
        </HeaderContent>
      </Spacings.Inset>
      {guidanceStepper ? guidanceStepper : null}
    </Layout.Main>
  </Layout.Grid>
);

export default Header;
