import React from "react";
import styled, { css } from "styled-components";

type Importance = "low" | "medium" | "high" | "max";

const importanceMapping = {
  high: 3,
  low: 1,
  max: 4,
  medium: 2,
};

const StyledImportanceIndicator = styled.div<{ highlighted: boolean }>`
  display: inline-block;
  background-color: ${(props) => props.theme.palette.mint[200]};
  width: 3.125em;
  height: ${(props) => props.theme.spacings.small};
  margin-right: 0.1875em;
  border-radius: 0.125em;
  ${(props) =>
    props.highlighted &&
    css`
      background-color: ${props.theme.palette.mint[600]};
    `}
`;

const ImportanceIndicator: React.FC<
  React.PropsWithChildren<{
    importance?: Importance;
  }>
> = ({ importance = "low" }) => {
  const indicators = Array.from({ length: 4 }).map((_, index) => (
    <StyledImportanceIndicator
      key={index}
      highlighted={index < importanceMapping[importance]}
    />
  ));

  return <div>{indicators}</div>;
};

export default ImportanceIndicator;
