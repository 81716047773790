import React from "react";

import GroupedLabelledRadio from "../GroupedLabelledRadio";
import RadioGroup from "./";

export const Example: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [value, setValue] = React.useState("long-answer");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <RadioGroup>
      <GroupedLabelledRadio
        name="answer"
        label="I enjoy answering questions with a really long speach that nobody can follow what I am talking about 🤓"
        value="long-answer"
        checked={value === "long-answer"}
        onChange={handleChange}
      />
      <GroupedLabelledRadio
        name="answer"
        label="I just answer questions 🤷‍♂️"
        value="short-answer"
        checked={value === "short-answer"}
        onChange={handleChange}
      />
    </RadioGroup>
  );
};

export const ErrorExample: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [value, setValue] = React.useState("long-answer");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <RadioGroup>
      <GroupedLabelledRadio
        name="error-answer"
        label="I enjoy answering questions with a really long speach that nobody can follow what I am talking about 🤓"
        value="long-answer"
        checked={value === "long-answer"}
        invalid
        onChange={handleChange}
      />
      <GroupedLabelledRadio
        name="error-answer"
        label="I just answer questions 🤷‍♂️"
        value="short-answer"
        checked={value === "short-answer"}
        invalid
        onChange={handleChange}
      />
    </RadioGroup>
  );
};
