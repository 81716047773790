import React from "react";
import styled from "styled-components";

import IconButton from "../IconButton";
import ChevronLeftIcon from "../Icons/ChevronLeftIcon";
import ChevronRightIcon from "../Icons/ChevronRightIcon";
import Text from "../Text";
import MonthsEnum from "./MonthsEnum";

const Month = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type Months = Readonly<
  [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
  ]
>;

export const DEFAULT_MONTHS: Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DEFAULT_SWITCH_MONTH_LABEL = (
  newMonth: MonthsEnum,
  newYear: number,
  monthNames: Months,
) => `To ${monthNames[newMonth]} ${newYear}`;

type CalendarMonthProps = {
  monthNames?: Months;
  currentDate: Date;
  toPrevMonthLabel?: typeof DEFAULT_SWITCH_MONTH_LABEL;
  toNextMonthLabel?: typeof DEFAULT_SWITCH_MONTH_LABEL;
  onPrevMonthClick?: () => void;
  onNextMonthClick?: () => void;
};

const CalendarMonth: React.FC<React.PropsWithChildren<CalendarMonthProps>> = ({
  monthNames = DEFAULT_MONTHS,
  currentDate,
  toPrevMonthLabel = DEFAULT_SWITCH_MONTH_LABEL,
  toNextMonthLabel = DEFAULT_SWITCH_MONTH_LABEL,
  onPrevMonthClick,
  onNextMonthClick,
}) => {
  const prevMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1,
  );
  const nextMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1,
  );

  return (
    <Month>
      <div>
        {onPrevMonthClick ? (
          <IconButton
            icon={<ChevronLeftIcon />}
            label={toPrevMonthLabel(
              prevMonthDate.getMonth(),
              prevMonthDate.getFullYear(),
              monthNames,
            )}
            onClick={onPrevMonthClick}
          />
        ) : null}
      </div>

      <Text textStyle="headline5" as="span">
        {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
      </Text>

      <div>
        {onNextMonthClick ? (
          <IconButton
            icon={<ChevronRightIcon />}
            label={toNextMonthLabel(
              nextMonthDate.getMonth(),
              nextMonthDate.getFullYear(),
              monthNames,
            )}
            onClick={onNextMonthClick}
          />
        ) : null}
      </div>
    </Month>
  );
};

export default CalendarMonth;
