import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled, { css } from "styled-components";

import {
  BackgroundStyles,
  BackgroundStylesProps,
  IconContainerStyles,
  InputSvgStyles,
  LabelStyles,
  LabelStylesProps,
  LabelTextStyles,
  LabelTextStylesProps,
} from "../PlayfulStyles/CheckboxAndRadioStyles";
import Text from "../Text";
import CheckboxChecked from "./CheckboxChecked.svg";

const Label = styled(Text).attrs({
  as: "label",
  isUncropped: true,
  textStyles: "bodyProlonged",
})<LabelStylesProps>`
  ${LabelStyles};
`;

const LabelText = styled.span<LabelTextStylesProps>`
  ${LabelTextStyles}
`;

export const StyledCheckboxChecked = styled(CheckboxChecked)`
  ${InputSvgStyles}
  background: ${(props) => props.theme.palette.white};
  border-radius: 2px;
  path:first-child {
    visibility: hidden;
  }
`;

const IconContainer = styled.span`
  ${IconContainerStyles}
`;

const Background = styled.span<BackgroundStylesProps>`
  ${BackgroundStyles}
`;

type InputProps = {
  invalid?: boolean;
  leanCheckboxOptions?: boolean;
};

const Input = styled.input<InputProps>`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked {
    + ${/* sc-selector */ StyledCheckboxChecked} {
      color: ${(props) => props.theme.palette.brand[700]};
      background: ${(props) => props.theme.palette.brand[700]};
      path:first-child {
        color: ${(props) => props.theme.palette.white};
        visibility: visible;
      }
    }
    ~ ${/* sc-selector */ IconContainer} {
      color: ${(props) => props.theme.palette.brand[700]};
    }
    ~ ${/* sc-selector */ Background} {
      ${(props) =>
        !props.leanCheckboxOptions &&
        css`
          background: ${props.theme.palette.brand[200]};
        `};
    }
  }

  &:focus {
    + ${/* sc-selector */ StyledCheckboxChecked} {
      box-shadow: 0 0 0 0.15em
        ${(props) => transparentize(0.7, props.theme.palette.brand[400])};
    }
  }

  &:hover {
    ~ ${/* sc-selector */ IconContainer} {
      color: ${(props) => props.theme.palette.brand[700]};
    }
    ~ ${/* sc-selector */ Background} {
      ${(props) =>
        !props.leanCheckboxOptions &&
        css`
          background: ${props.theme.palette.brand[300]};
        `};
    }
  }

  &:hover:not(:checked) + ${/* sc-selector */ StyledCheckboxChecked} {
    background: ${(props) => props.theme.palette.gray[50]};
  }

  ${(props) =>
    props.invalid &&
    css`
      & + ${/* sc-selector */ StyledCheckboxChecked} {
        color: ${props.theme.palette.error.main};
      }
      & ~ ${/* sc-selector */ IconContainer} {
        color: ${props.theme.palette.error.main};
      }
      & ~ ${/* sc-selector */ Background} {
        background: ${props.leanCheckboxOptions
          ? "transparent"
          : props.theme.palette.error[100]};
      }

      &:checked {
        + ${/* sc-selector */ StyledCheckboxChecked} {
          color: ${props.theme.palette.error.main};
          background: ${props.theme.palette.error.main};
        }
        ~ ${/* sc-selector */ IconContainer} {
          color: ${props.theme.palette.error.main};
        }
        ~ ${/* sc-selector */ Background} {
          background: ${props.leanCheckboxOptions
            ? "transparent"
            : props.theme.palette.error[100]};
        }
      }

      &:focus {
        + ${/* sc-selector */ StyledCheckboxChecked} {
          box-shadow: 0 0 0 0.15em
            ${transparentize(0.7, props.theme.palette.error.main)};
        }
        ~ ${/* sc-selector */ IconContainer} {
          color: ${props.theme.palette.error.main};
        }
        ~ ${/* sc-selector */ Background} {
          background: ${props.leanCheckboxOptions
            ? "transparent"
            : props.theme.palette.error[100]};
        }
      }

      &:hover {
        ~ ${/* sc-selector */ IconContainer} {
          color: ${props.theme.palette.error.main};
        }
        ~ ${/* sc-selector */ Background} {
          background: ${props.leanCheckboxOptions
            ? "transparent"
            : props.theme.palette.error[200]};
        }
      }

      &:hover:not(:checked) + ${/* sc-selector */ StyledCheckboxChecked} {
        path:first-child {
          color: ${props.theme.palette.gray[50]};
          visibility: visible;
        }
      }
    `}
`;

export type PlayfulCheckboxProps = Omit<
  JSX.IntrinsicElements["input"],
  "type" | "className" | "style"
> &
  Pick<JSX.IntrinsicElements["label"], "className" | "style"> & {
    invalid?: boolean;
    icon?: React.ReactNode;
    leanCheckboxOptions?: boolean;
    children: React.ReactNode | string;
    ref?: React.Ref<HTMLInputElement>;
    scale?: "small" | "medium";
  };

const PlayfulCheckbox = React.forwardRef<
  HTMLInputElement,
  PlayfulCheckboxProps
>(
  (
    {
      className,
      disabled,
      icon,
      invalid,
      leanCheckboxOptions,
      scale = "small",
      style,
      children,
      ...inputProps
    },
    ref,
  ) =>
    children ? (
      <Label
        className={className}
        style={style}
        scale={scale}
        hasIcon={Boolean(icon)}
        leanCheckboxOptions={leanCheckboxOptions}
        disabled={disabled}
      >
        <Input
          type="checkbox"
          invalid={invalid}
          disabled={disabled}
          leanCheckboxOptions={leanCheckboxOptions}
          ref={ref}
          {...inputProps}
        />
        <StyledCheckboxChecked hasIcon={Boolean(icon)} />
        {icon ? <IconContainer>{icon}</IconContainer> : null}
        <Background scale={scale} leanCheckboxOptions={leanCheckboxOptions} />
        <LabelText hasIcon={Boolean(icon)}>{children}</LabelText>
      </Label>
    ) : null,
);

export default PlayfulCheckbox;
