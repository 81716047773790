import { GetToggleButtonPropsOptions } from "downshift";
import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled, { css } from "styled-components";

import ChevronBottomIcon from "../Icons/ChevronBottomIcon";
import ChevronTopIcon from "../Icons/ChevronTopIcon";
import CloseIcon from "../Icons/CloseIcon";
import Spacings from "../Spacings";
import Text from "../Text";

const FilterButtonWrapper = styled.div`
  display: flex;
  svg {
    color: ${(props) => props.theme.palette.brand[700]};
  }
`;

export const BaseButton = css`
  background-color: ${(props) => props.theme.palette.brand[200]};
  border: none;
  cursor: pointer;
  height: 30px;
  outline: none;
  &:focus {
    box-shadow: 0 0 0 3px
      ${(props) => transparentize(0.5, props.theme.palette.brand[100])};
  }
`;

export const MainButton = styled.button<{
  showInfoButton: boolean;
  showResetButton: boolean;
}>`
  ${BaseButton};
  border-radius: ${(props) => (props.showInfoButton ? "0" : "15")}px
    ${(props) => (props.showResetButton ? "0" : "15")}px
    ${(props) => (props.showResetButton ? "0" : "15")}px
    ${(props) => (props.showInfoButton ? "0" : "15")}px;
  padding: 0 ${(props) => props.theme.spacings.small};

  /* avoid wrapping filter text */
  white-space: nowrap;
`;

export const ResetButton = styled.button`
  ${BaseButton};
  border-radius: 0 15px 15px 0;
  padding: 0 ${(props) => props.theme.spacings.small};
  background-color: ${(props) => props.theme.palette.brand[300]};
`;

export type FilterButtonProps = {
  ariaLabel: string;
  getToggleButtonProps: (options?: GetToggleButtonPropsOptions) => any;
  id: string;
  isOpen: boolean;
  label: string;
  onClick?: (event: React.SyntheticEvent) => void;
  onReset?: (event: React.SyntheticEvent) => void;
  resetLabel?: string;
  showInfoButton?: boolean;
  showResetButton?: boolean;
};

const FilterButton: React.FC<React.PropsWithChildren<FilterButtonProps>> = ({
  ariaLabel,
  getToggleButtonProps,
  id,
  isOpen,
  label,
  onClick,
  onReset,
  resetLabel = "reset",
  showInfoButton = false,
  showResetButton = false,
}) => (
  <FilterButtonWrapper>
    <MainButton
      id={id}
      onClick={onClick}
      showInfoButton={showInfoButton}
      showResetButton={showResetButton}
      {...getToggleButtonProps({ "aria-label": ariaLabel })}
    >
      <Spacings.Inline alignItems="center">
        <Text isUncropped>{label}</Text>
        {isOpen ? <ChevronTopIcon /> : <ChevronBottomIcon />}
      </Spacings.Inline>
    </MainButton>
    {showResetButton && (
      <ResetButton
        aria-label={resetLabel}
        onClick={onReset}
        data-testid="reset-filter-button"
      >
        <CloseIcon />
      </ResetButton>
    )}
  </FilterButtonWrapper>
);

export default FilterButton;
