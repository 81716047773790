import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  max-width: ${(props) => props.theme.typography.lineLength};
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: calc(-${(props) => props.theme.spacings.small} / 2);

  > * {
    width: 10em;
    margin: calc(${(props) => props.theme.spacings.small} / 2);
  }

  @supports (display: grid) {
    display: grid;
    grid-gap: ${(props) => props.theme.spacings.small};
    grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
    margin: 0;

    > * {
      width: auto;
      margin: 0;
    }
  }
`;

type ContainerProps = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

type LabelledIconRadioGroupProps = ContainerProps;

const LabelledIconRadioGroup: React.FC<
  React.PropsWithChildren<LabelledIconRadioGroupProps>
> = ({ children, ...restProps }) => (
  <Container {...restProps}>
    <Grid>{children}</Grid>
  </Container>
);

export default LabelledIconRadioGroup;
