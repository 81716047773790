import React from "react";
import styled, { css } from "styled-components";

import Spacings from "../../Spacings";
import Text from "../../Text";

const StyledLink = styled.div<{ isActive: boolean }>`
  ${(props) =>
    props.isActive &&
    css`
      border-bottom: 2px solid ${props.theme.palette.gray[600]};
    `};
`;

type NavigationLinkProps = {
  icon: React.ReactNode;
  inline?: boolean;
  isActive?: boolean;
  label: string;
};

const NavigationLink: React.FC<
  React.PropsWithChildren<NavigationLinkProps>
> = ({ icon, inline = false, isActive = false, label }) => (
  <StyledLink isActive={isActive}>
    {inline ? (
      <Spacings.Inset>
        <Spacings.Inline alignItems="center">
          {icon}
          <Text textStyle="body" isStrong={isActive} as="span">
            {label}
          </Text>
        </Spacings.Inline>
      </Spacings.Inset>
    ) : (
      <Spacings.Inset>
        <Spacings.Stack alignItems="center">
          {icon}
          <Text isStrong={isActive}>{label}</Text>
        </Spacings.Stack>
      </Spacings.Inset>
    )}
  </StyledLink>
);

export default NavigationLink;
