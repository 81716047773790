import React from "react";
import styled, { css } from "styled-components";

import Check1Icon from "../Icons/Check1Icon";
import ResponsiveHelper from "../ResponsiveHelper";
import Spacings from "../Spacings";
import Text from "../Text";

const STEP_CIRCLE_SIZE = "30px";

/**
 * When step is active its circle scales by 1.5. To insure that this item won't go over some elements we use 8px.
 * Why 8px? If you find the way how to calculate this number feel free to adjust this.
 */
export const STEP_CIRCLE_SCALE_OFFSET = "8px";

type StepCircleProps = {
  isActive?: boolean;
  isChecked?: boolean;
  hasErrors?: boolean;
};

const StepCircle = styled.div<StepCircleProps>`
  font-feature-settings: "tnum" off;
  width: ${STEP_CIRCLE_SIZE};
  height: ${STEP_CIRCLE_SIZE};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.gray[200]};
  color: ${(props) => props.theme.palette.gray[800]};
  z-index: 1;
  ${(props) =>
    props.isChecked &&
    css`
      background-color: ${props.theme.palette.brand.main};
    `}
  ${(props) =>
    props.isActive &&
    css`
      transform: scale(1.5);
      margin-bottom: ${STEP_CIRCLE_SCALE_OFFSET};
      background-color: ${props.theme.palette.brand.dark};

      ::before {
        content: " ";
        position: absolute;
        border-radius: 50%;
        border: ${props.theme.borders.width.small}px solid
          ${props.theme.palette.white};
        /* To have a room until the parent border. */
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
      }
    `}
  ${(props) =>
    props.hasErrors &&
    css`
      background-color: ${props.theme.palette.error.main};
    `}
`;

const STEP_DIVIDER_HEIGHT = "2px";

type StepDividerCSSProps = {
  isActive?: boolean;
  isChecked?: boolean;
};

const stepDividerCSS = css<StepDividerCSSProps>`
  content: " ";
  position: absolute;
  height: ${STEP_DIVIDER_HEIGHT};
  left: 0;

  /* prettier-ignore */
  top: calc(${STEP_CIRCLE_SIZE} / 2 - ${STEP_DIVIDER_HEIGHT} / 2 + ${STEP_CIRCLE_SCALE_OFFSET});
`;

type StepWrapperProps = {
  isActive?: boolean;
  isChecked?: boolean;
};

const StepWrapper = styled.li<StepWrapperProps>`
  list-style-type: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  :not(:first-child)::before {
    ${stepDividerCSS}
    left: 0;
    right: 50%;
    background-color: ${(props) =>
      props.isActive || props.isChecked
        ? props.theme.palette.brand.main
        : props.theme.palette.gray[200]};
  }

  :not(:last-child)::after {
    ${stepDividerCSS}
    left: 50%;
    right: 0;
    background-color: ${(props) =>
      props.isChecked
        ? props.theme.palette.brand.main
        : props.theme.palette.gray[200]};
  }
`;

const StepTitle = styled(Text)`
  text-align: center;
`;

const StepButton = styled.button`
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: 0;
  background: transparent;

  /**
  * When step is active its circle scales. To insure that this item won't go outside
  * the container we use its scale offset for top padding.
  */
  padding-top: ${STEP_CIRCLE_SCALE_OFFSET};

  &:focus,
  &:hover {
    z-index: 1;
  }

  &[tabIndex="-1"] {
    cursor: default;
    outline: none;
  }
`;

const StepButtonStack = styled(Spacings.Stack)`
  height: 100%;
`;

type StepProps = {
  isActive?: boolean;
  isChecked?: boolean;
  hasErrors?: boolean;
  number: number;
  title?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Step: React.FC<React.PropsWithChildren<StepProps>> = ({
  isActive,
  isChecked,
  hasErrors,
  number,
  title,
  onClick,
}) => (
  <StepWrapper isActive={isActive} isChecked={isChecked}>
    <StepButton
      onClick={onClick}
      role={onClick ? undefined : "presentation"}
      tabIndex={onClick ? undefined : -1}
    >
      <StepButtonStack alignItems="center">
        <StepCircle
          isChecked={isChecked}
          isActive={isActive}
          hasErrors={hasErrors}
          aria-hidden="true"
        >
          {isChecked && !hasErrors && (
            <Check1Icon
              isOnDarkBackground
              scale="tiny"
              data-testid="checked-step-icon"
            />
          )}
          <Text
            as="span"
            isUncropped
            priority={
              isActive || isChecked || hasErrors ? "primary" : "secondary"
            }
            isOnDarkBackground={isActive || isChecked || hasErrors}
            hidden={isChecked && !hasErrors}
          >
            {number}
          </Text>
        </StepCircle>

        <ResponsiveHelper>
          {(breakpoint) => (
            <StepTitle
              textStyle={isActive ? "headline6" : "body"}
              as="span"
              priority="secondary"
              isUncropped
              hidden={breakpoint === "mobile"}
              aria-current={isActive ? "step" : undefined}
            >
              {title}
            </StepTitle>
          )}
        </ResponsiveHelper>
      </StepButtonStack>
    </StepButton>
  </StepWrapper>
);

export default Step;
