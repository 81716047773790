import React from "react";

import CalendarGrid from "./CalendarGrid";

type WeekDays = Readonly<
  [string, string, string, string, string, string, string]
>;

const DEFAULT_WEEK_DAYS: WeekDays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

type CalendarWeekDaysProps = {
  weekDays?: WeekDays;
};

const CalendarWeekDays: React.FC<
  React.PropsWithChildren<CalendarWeekDaysProps>
> = ({ weekDays = DEFAULT_WEEK_DAYS }) => (
  <CalendarGrid.Row>
    {weekDays.map((weekDay) => (
      <CalendarGrid.Column key={weekDay}>
        <CalendarGrid.Content priority="secondary" aria-hidden="true">
          {weekDay}
        </CalendarGrid.Content>
      </CalendarGrid.Column>
    ))}
  </CalendarGrid.Row>
);

export default CalendarWeekDays;
