import React from "react";
import styled from "styled-components";

import Divider from "../../Divider";
import Inline from "../../Inline";
import Spacings from "../../Spacings";

const StyledNavigationBar = styled.nav`
  padding: ${(props) => props.theme.spacings.gigantic} 0
    ${(props) => props.theme.spacings.huge};
`;

export const TabNavigation: React.FC<
  React.PropsWithChildren<{ children: React.ReactNode }>
> = ({ children, ...props }) => (
  <Spacings.Stack>
    <StyledNavigationBar {...props}>
      <Inline scale="gigantic">{children}</Inline>
      <Divider priority="secondary" />
    </StyledNavigationBar>
  </Spacings.Stack>
);

export default TabNavigation;
