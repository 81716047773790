import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled, { css } from "styled-components";

import {
  BackgroundStyles,
  BackgroundStylesProps,
  IconContainerStyles,
  InputSvgStyles,
  LabelStyles,
  LabelStylesProps,
  LabelTextStyles,
  LabelTextStylesProps,
} from "../PlayfulStyles/CheckboxAndRadioStyles";
import Text from "../Text";
import RadioChecked from "./RadioChecked.svg";

const Label = styled(Text).attrs({
  as: "label",
  isUncropped: true,
  textStyles: "bodyProlonged",
})<LabelStylesProps>`
  ${LabelStyles};
`;

const LabelText = styled.span<LabelTextStylesProps>`
  ${LabelTextStyles}
`;

export const StyledRadioChecked = styled(RadioChecked)`
  ${InputSvgStyles}
  border-radius: 50%;
  circle:last-child {
    visibility: hidden;
  }
`;

const IconContainer = styled.span`
  ${IconContainerStyles}
`;

const Background = styled.span<BackgroundStylesProps>`
  ${BackgroundStyles}
`;

type InputProps = {
  invalid?: boolean;
};

const Input = styled.input<InputProps>`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked {
    + ${/* sc-selector */ StyledRadioChecked} {
      color: ${(props) => props.theme.palette.brand[700]};

      circle:last-child {
        visibility: visible;
      }
    }
    ~ ${/* sc-selector */ IconContainer} {
      color: ${(props) => props.theme.palette.brand[700]};
    }
    ~ ${/* sc-selector */ Background} {
      background: ${(props) => props.theme.palette.brand[200]};
    }
  }

  &:focus {
    + ${/* sc-selector */ StyledRadioChecked} {
      box-shadow: 0 0 0 0.15em
        ${(props) => transparentize(0.7, props.theme.palette.brand[400])};
    }
  }

  &:hover {
    ~ ${/* sc-selector */ IconContainer} {
      color: ${(props) => props.theme.palette.brand[700]};
    }
    ~ ${/* sc-selector */ Background} {
      background: ${(props) => props.theme.palette.brand[300]};
    }
  }

  &:hover:not(:checked) + ${/* sc-selector */ StyledRadioChecked} {
    circle:first-child {
      fill: ${(props) => props.theme.palette.gray[50]};
    }
  }

  ${(props) =>
    props.invalid &&
    css`
      & + ${/* sc-selector */ StyledRadioChecked} {
        color: ${props.theme.palette.error.main};
      }

      & ~ ${/* sc-selector */ IconContainer} {
        color: ${props.theme.palette.error.main};
      }

      & ~ ${/* sc-selector */ Background} {
        background: ${props.theme.palette.error[100]};
      }

      &:checked {
        + ${/* sc-selector */ StyledRadioChecked} {
          color: ${props.theme.palette.error.main};
        }
        ~ ${/* sc-selector */ IconContainer} {
          color: ${props.theme.palette.error.main};
        }
      }

      &:focus {
        + ${/* sc-selector */ StyledRadioChecked} {
          box-shadow: 0 0 0 0.15em
            ${transparentize(0.7, props.theme.palette.error.main)};
        }
        ~ ${/* sc-selector */ IconContainer} {
          color: ${props.theme.palette.error.main};
        }
        ~ ${/* sc-selector */ Background} {
          background: ${props.theme.palette.error[100]};
        }
      }

      &:hover {
        ~ ${/* sc-selector */ IconContainer} {
          color: ${props.theme.palette.error.main};
        }
        ~ ${/* sc-selector */ Background} {
          background: ${props.theme.palette.error[200]};
        }
      }

      &:hover:not(:checked) + ${/* sc-selector */ StyledRadioChecked} {
        circle:first-child {
          fill: ${props.theme.palette.gray[50]};
        }
      }
    `}
`;

export type PlayfulRadioProps = Omit<
  JSX.IntrinsicElements["input"],
  "type" | "className" | "style"
> &
  Pick<JSX.IntrinsicElements["label"], "className" | "style"> & {
    invalid?: boolean;
    icon?: React.ReactNode;
    children: React.ReactNode | string;
    ref?: React.Ref<HTMLInputElement>;
    scale?: "small" | "medium";
  };

const PlayfulRadio = React.forwardRef<HTMLInputElement, PlayfulRadioProps>(
  (
    {
      className,
      disabled,
      icon,
      invalid,
      scale = "small",
      style,
      children,
      ...inputProps
    },
    ref,
  ) =>
    children ? (
      <Label
        className={className}
        style={style}
        scale={scale}
        hasIcon={Boolean(icon)}
        disabled={disabled}
      >
        <Input
          type="radio"
          invalid={invalid}
          disabled={disabled}
          ref={ref}
          {...inputProps}
        />
        <StyledRadioChecked hasIcon={Boolean(icon)} />
        {icon ? <IconContainer>{icon}</IconContainer> : null}
        <Background scale={scale} />
        <LabelText hasIcon={Boolean(icon)}>{children}</LabelText>
      </Label>
    ) : null,
);

export default PlayfulRadio;
