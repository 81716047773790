import React, { useState } from "react";
import ReactPhoneInput, { CountryData } from "react-phone-input-2";
import styled from "styled-components";

import PlayfulInputMedium from "../PlayfulInput/PlayfulInputMedium";
import { InputStylesProps } from "../PlayfulStyles/InputStyles";
import labelsDE from "./locale/de.json";
import ReactPhoneInputStyles from "./ReactPhoneInputStyles";

const StyledPhoneInputWrapper = styled.div<InputStylesProps>`
  ${ReactPhoneInputStyles};
`;

const TOP_COUNTY_CODES: Array<keyof typeof labelsDE> = [
  "de",
  "at",
  "ch",
  "nl",
  "us",
];

export type PlayfulInputPhoneEvent = {
  target: {
    name: string;
    value: string;
  };
};

export type PlayfulInputPhoneProps = InputStylesProps & {
  inputClass: string;
  label?: string;
  autoComplete?: string;
  value: string;
  name: string;
  id: string;
  onChange?: (event: PlayfulInputPhoneEvent) => void;
  onBlur?: (event: PlayfulInputPhoneEvent) => void;
};

const PhoneInput: React.FC<React.PropsWithChildren<PlayfulInputPhoneProps>> = (
  props,
) => {
  const [specialLabel, setSpecialLabel] = useState(props.label || labelsDE.de);

  return (
    <ReactPhoneInput
      country="de"
      localization={labelsDE}
      autoFormat={false}
      containerClass="react-phone-container"
      inputClass={`react-phone-input ${props.inputClass}`}
      buttonClass="react-phone-country"
      dropdownClass="react-phone-dropdown"
      inputProps={{
        autoComplete: props.autoComplete || "tel",
        "data-testid": "phone-input-field",
        id: props.id,
        name: props.name,
      }}
      onChange={(value, data: CountryData) => {
        const newSpecialLabel =
          props.label || labelsDE[data.countryCode as keyof typeof labelsDE];
        const event: PlayfulInputPhoneEvent = {
          target: {
            name: props.name,
            value: `+${value}`,
          },
        };

        props.onChange?.(event);

        if (specialLabel !== newSpecialLabel) {
          setSpecialLabel(newSpecialLabel);

          /**
           * We focus on the input whenever a country change is made so that when users
           * choose a country in the dropdown they can immediately start typing
           * their number.
           *
           * Pressing tab when the input has focus will put focus on the dropdown
           * button so if a user with keyboard navigation chose the wrong country
           * they can easily go back and choose a new country.
           */
          const input: HTMLInputElement | null = document.querySelector(
            `.${props.inputClass}`,
          );
          input?.focus();
        }
      }}
      placeholder=""
      preferredCountries={TOP_COUNTY_CODES}
      onlyCountries={Object.keys(labelsDE)}
      specialLabel={specialLabel}
      value={props.value}
      disabled={props.disabled}
    />
  );
};

const PlayfulInputPhone: React.FC<
  React.PropsWithChildren<PlayfulInputPhoneProps>
> = React.memo(
  ({ disabled, invalid, label, scale = "small", valid, ...props }) => (
    <StyledPhoneInputWrapper
      disabled={disabled}
      invalid={invalid}
      scale={scale}
      valid={valid}
    >
      {scale === "small" ? (
        <PhoneInput {...props} disabled={disabled} />
      ) : (
        <PlayfulInputMedium label={label}>
          <PhoneInput
            {...props}
            scale={scale}
            disabled={disabled}
            label={label}
          />
        </PlayfulInputMedium>
      )}
    </StyledPhoneInputWrapper>
  ),
);

export default PlayfulInputPhone;
