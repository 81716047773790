import React from "react";
import styled, { css } from "styled-components";

import GroupedLabelledCheckbox from "../GroupedLabelledCheckbox";
import GroupedLabelledRadio from "../GroupedLabelledRadio";
import Check1Icon from "../Icons/Check1Icon";

type ContainerProps = {
  isGrouped?: boolean;
};

const Container = styled.div<ContainerProps>`
  position: relative;

  ${(props) =>
    props.isGrouped &&
    css`
      /* prettier-ignore */
      & > ${/* sc-selector */ GroupedLabelledRadio}:first-child,
      & > ${/* sc-selector */ GroupedLabelledCheckbox}:first-child {
        border-radius: 0;
      }

      &:first-child
        > ${/* sc-selector */ GroupedLabelledRadio}:first-child,
        &:first-child
        > ${/* sc-selector */ GroupedLabelledCheckbox}:first-child {
        border-top-left-radius: ${props.theme.borders.radius.medium}px;
        border-top-right-radius: ${props.theme.borders.radius.medium}px;
      }

      &:last-child
        > ${/* sc-selector */ GroupedLabelledRadio}:first-child,
        &:last-child
        > ${/* sc-selector */ GroupedLabelledCheckbox}:first-child {
        border-bottom-left-radius: ${props.theme.borders.radius.medium}px;
        border-bottom-right-radius: ${props.theme.borders.radius.medium}px;
      }
    `}
`;

const IconContainer = styled.span`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(150%, -50%);
`;

type FieldWithAffirmationMarkProps = {
  isGrouped?: boolean;
  shouldShowAffirmationMark?: boolean;
  children?: React.ReactNode;
};

const FieldWithAffirmationMark: React.FC<
  React.PropsWithChildren<FieldWithAffirmationMarkProps>
> = ({ isGrouped, shouldShowAffirmationMark, children }) => (
  <Container isGrouped={isGrouped}>
    {children}

    {shouldShowAffirmationMark && (
      <IconContainer data-testid="valid-mark">
        <Check1Icon color="auxiliary" aria-hidden="true" />
      </IconContainer>
    )}
  </Container>
);

export default FieldWithAffirmationMark;
