import React from "react";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.spacings.small};
  grid-template-columns: repeat(2, minmax(auto, 50%));
  grid-auto-rows: 1fr;
  margin: 0;

  > * {
    width: auto;
    margin: 0;
  }
`;

type ContainerProps = React.PropsWithoutRef<JSX.IntrinsicElements["div"]>;

type PlayfulInputGroupProps = ContainerProps;

const PlayfulInputGroup: React.FC<
  React.PropsWithChildren<PlayfulInputGroupProps>
> = ({ children, ...restProps }) => <Grid {...restProps}>{children}</Grid>;

export default PlayfulInputGroup;
