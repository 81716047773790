import { format, formatDuration, intervalToDuration } from "date-fns";
import { de } from "date-fns/locale";
import React from "react";
import styled, { css } from "styled-components";

import Chip from "../Chip";

const TimeSlot = styled(Chip)`
  ${(props) =>
    props["aria-pressed"] &&
    css`
      background: ${props.theme.palette.brand[100]};
      border-color: ${props.theme.palette.brand.main};
      color: ${props.theme.palette.brand[800]};
    `}
`;

export type TimeSlot = {
  start: Date;
  end: Date;
};

type TimeSlotPickerProps = {
  timeSlots: TimeSlot[];
  selectedTimeSlot: TimeSlot | null;
  onTimeSlotSelect: (timeSlot: TimeSlot) => void;
  shouldDisplayDuration?: boolean;
  shouldDisplayEndTime?: boolean;
};

const formatTimeSlot = (
  timeSlot: TimeSlot,
  options: {
    shouldDisplayDuration?: boolean;
    shouldDisplayEndTime?: boolean;
  },
) => {
  const startTime = format(timeSlot.start, "HH:mm");
  const endTime = format(timeSlot.end, "HH:mm");
  const duration = intervalToDuration(timeSlot);

  let formattedTimeSlot = startTime;

  if (options?.shouldDisplayEndTime) {
    formattedTimeSlot += ` - ${endTime}`;
  }

  if (options?.shouldDisplayDuration) {
    formattedTimeSlot += ` (${formatDuration(duration, {
      locale: de,
    })})`;
  }

  return formattedTimeSlot;
};

const isSameTimeSlot = (a: TimeSlot, b: TimeSlot) =>
  a.start.toISOString() === b.start.toISOString() &&
  a.end.toISOString() === b.end.toISOString();

const TimeSlotPicker: React.FC<
  React.PropsWithChildren<TimeSlotPickerProps>
> = ({
  onTimeSlotSelect,
  selectedTimeSlot,
  shouldDisplayDuration,
  shouldDisplayEndTime,
  timeSlots,
}) =>
  timeSlots
    .sort((a, b) => a.start.getTime() - b.start.getTime())
    .map((timeSlot) => {
      const isTimeSlotSelected =
        (selectedTimeSlot && isSameTimeSlot(selectedTimeSlot, timeSlot)) ||
        false;
      return (
        <TimeSlot
          type="button"
          data-testid="time-slot"
          key={timeSlot.start.toISOString()}
          onClick={() => onTimeSlotSelect(timeSlot)}
          aria-pressed={isTimeSlotSelected}
        >
          {formatTimeSlot(timeSlot, {
            shouldDisplayDuration,
            shouldDisplayEndTime,
          })}
        </TimeSlot>
      );
    });

export default TimeSlotPicker;
