import React from "react";

import ResponsiveContainer from "../ResponsiveContainer";

type ResponsiveSwitcherProps = {
  mobile?: React.ReactElement;
  tablet?: React.ReactElement;
  desktop?: React.ReactElement;
};

const ResponsiveSwitcher: React.FC<
  React.PropsWithChildren<ResponsiveSwitcherProps>
> = ({ mobile, tablet, desktop, children, ...props }) => (
  <React.Fragment>
    <ResponsiveContainer showOn={["mobile"]}>
      {mobile ? React.cloneElement(mobile, props, children) : children}
    </ResponsiveContainer>
    <ResponsiveContainer showOn={["tablet"]}>
      {tablet ? React.cloneElement(tablet, props, children) : children}
    </ResponsiveContainer>
    <ResponsiveContainer showOn={["desktop"]}>
      {desktop ? React.cloneElement(desktop, props, children) : children}
    </ResponsiveContainer>
  </React.Fragment>
);

export default ResponsiveSwitcher;
