import Downshift from "downshift";
import React from "react";
import styled from "styled-components";

import DropdownMenu from "../DropdownMenu";
import InformationCircleIcon from "../Icons/InformationCircleIcon";
import Markdown from "../Markdown";
import ResponsiveContainer from "../ResponsiveContainer";
import Spacings from "../Spacings";
import { BaseButton } from "./FilterButton";

export const InfoButton = styled.button`
  ${BaseButton};
  border-radius: 15px 0 0 15px;
  padding: 0 0 0 ${(props) => props.theme.spacings.small};
  > svg {
    color: ${(props) => props.theme.palette.brand[700]};
  }
`;

export type FilterInfoProps = {
  ariaLabel: string;
  description: string;
  label: string;
};

const FilterInfo: React.FC<React.PropsWithChildren<FilterInfoProps>> = ({
  ariaLabel,
  description,
  label,
}) => (
  <Downshift>
    {({ getMenuProps, getToggleButtonProps, isOpen, closeMenu }) => (
      <div data-testid="filter-info">
        <InfoButton {...getToggleButtonProps({ "aria-label": ariaLabel })}>
          <InformationCircleIcon data-testid="information-circle-icon" />
        </InfoButton>
        <DropdownMenu
          getMenuProps={getMenuProps}
          label={label}
          isOpen={isOpen}
          onCloseMenu={closeMenu}
        >
          {isOpen && (
            <React.Fragment>
              <ResponsiveContainer showOn={["mobile"]}>
                <Spacings.Inset scale="medium">
                  <Markdown>{description}</Markdown>
                </Spacings.Inset>
              </ResponsiveContainer>
              <ResponsiveContainer showOn={["tablet", "desktop"]}>
                <Spacings.Inset>
                  <Markdown>{description}</Markdown>
                </Spacings.Inset>
              </ResponsiveContainer>
            </React.Fragment>
          )}
        </DropdownMenu>
      </div>
    )}
  </Downshift>
);

export default FilterInfo;
