import React from "react";
import styled from "styled-components";

import Divider from "../Divider";
import Spacings from "../Spacings";

export type TableProps = {
  children: React.ReactNode;
  describedBy?: string;
  className?: string;
};

const Table: React.FC<React.PropsWithChildren<TableProps>> = ({
  children,
  describedBy,
  className,
}) => (
  <table
    {...(describedBy ? { "aria-describedby": describedBy } : {})}
    className={className}
  >
    <tbody>{children}</tbody>
  </table>
);

type RowProps = { children: React.ReactNode };

const Row: React.FC<React.PropsWithChildren<RowProps>> = ({ children }) => (
  <tr>{children}</tr>
);

type AlignProps = { textAlign?: string; verticalAlign?: string };

export const StyledCell = styled.td<AlignProps>`
  text-align: ${(props) => props.textAlign || "left"};
  vertical-align: ${(props) => props.verticalAlign || "top"};
`;

export type CellProps = AlignProps & {
  children: React.ReactNode;
  isHeaderFor?: "row" | "column";
  colSpan?: number;
};

const Cell: React.FC<React.PropsWithChildren<CellProps>> = ({
  children,
  isHeaderFor,
  colSpan,
  ...alignProps
}) => (
  <StyledCell
    as={isHeaderFor ? "th" : "td"}
    colSpan={colSpan}
    {...(isHeaderFor ? { scope: isHeaderFor } : {})}
    {...alignProps}
  >
    {children}
  </StyledCell>
);

type DividerProps = {
  columns: number;
};

const TableDivider: React.FC<React.PropsWithChildren<DividerProps>> = ({
  columns,
}) => (
  <Row>
    <Cell colSpan={columns}>
      <Spacings.Inset>
        <Divider data-testid="table-divider" />
      </Spacings.Inset>
    </Cell>
  </Row>
);

export default {
  Cell,
  Divider: TableDivider,
  Row,
  Table,
};
