import React, { useState } from "react";

import GuidanceStepper from "./index";

export const GuidanceStepperExample: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [activeStepId, setActiveStepId] = useState("1");

  return (
    <GuidanceStepper
      aria-label="Example guidance"
      activeStepId={activeStepId}
      steps={[
        {
          id: "1",
          title: "Step 1",
        },
        {
          hasErrors: true,
          id: "2",
          title: "Step 2 with errors",
        },
        {
          id: "3",
          title: "Step 3",
        },
      ]}
      onStepChange={setActiveStepId}
    />
  );
};
