import React from "react";
import styled from "styled-components";

import Text from "../Text";

const StyledLink = styled.a`
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.palette.white};
    text-decoration: underline;
  }
`;

export type FooterLinkProps = {
  href: string;
  text: string;
  onClick?: () => void;
};

export const FooterLink: React.FC<React.PropsWithChildren<FooterLinkProps>> = ({
  href,
  text,
  ...props
}) => (
  <StyledLink href={href} {...props}>
    <Text textStyle="caption" isOnDarkBackground>
      {text}
    </Text>
  </StyledLink>
);

export default FooterLink;
