import React from "react";

import { StyledLabel, StyledLabelText } from "../PlayfulStyles/InputStyles";

type PlayfulInputMediumProps = {
  children: React.ReactNode;
  label?: string;
};

const PlayfulInputMedium: React.FC<
  React.PropsWithChildren<PlayfulInputMediumProps>
> = ({ children, label }) => (
  <StyledLabel>
    {children}
    <StyledLabelText as="span" textStyle="bodyProlonged">
      {label}
    </StyledLabelText>
  </StyledLabel>
);

export default PlayfulInputMedium;
