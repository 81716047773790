import React from "react";
import styled from "styled-components";

import FooterLinks from ".";
import Footer from "../Footer";
import FooterLink from "../FooterLink";

const CssReset = styled.div`
  & ul {
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }
`;

const FooterExample: React.FC<React.PropsWithChildren<unknown>> = () => (
  <CssReset>
    <Footer>
      <FooterLinks>
        <FooterLink href="/about" text="About" />
        <FooterLink href="/imprint" text="Imprint" />
        <FooterLink href="/faq" text="FAQ" />
      </FooterLinks>
    </Footer>
  </CssReset>
);

export default FooterExample;
