import React from "react";

import Button from "../Button";
import Text from "../Text";
import Dialog from "./";
import useDialogState from "./useDialogState";

export const DefaultDialogExample: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dialogState = useDialogState();

  return (
    <div>
      <Button {...dialogState.toggle}>Open Dialog</Button>
      <Dialog
        visible={dialogState.visible}
        toggleRef={dialogState.toggle.ref}
        onClose={dialogState.close}
        header={
          <Text textStyle="headline3" as="h1">
            A simple dialog
          </Text>
        }
        content={<Text>Let me tell you all about my awesome component!</Text>}
        closeLabel="Close dialog"
      />
    </div>
  );
};
