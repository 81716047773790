import React from "react";
import styled from "styled-components";

import Spacings from "../Spacings";

const StyledFooter = styled.footer`
  background-color: ${(props) => props.theme.palette.backgrounds.brand};
`;

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.layout.containerWidth};
`;

export type FooterProps = {
  children?: React.ReactNode;
};

export const Footer: React.FC<React.PropsWithChildren<FooterProps>> = ({
  children,
}) => (
  <StyledFooter>
    <StyledContainer>
      <Spacings.Inset scale="big">{children}</Spacings.Inset>
    </StyledContainer>
  </StyledFooter>
);

export default Footer;
