import React from "react";
import styled from "styled-components";

import Spacings from "../Spacings";
import Text, { TextProps } from "../Text";

const StyledInset = styled(Spacings.Inset)`
  background-color: ${(props) => props.theme.palette.backgrounds.gray};
  border-radius: ${(props) => props.theme.borders.radius.small}px;
`;

type FormDescriptionProps = TextProps &
  React.PropsWithoutRef<JSX.IntrinsicElements["p"]>;

const FormDescription: React.FC<
  React.PropsWithChildren<FormDescriptionProps>
> = ({ className, ...restProps }) => (
  <StyledInset scale="medium" className={className}>
    <Text priority="secondary" textStyle="caption" {...restProps} />
  </StyledInset>
);

export default FormDescription;
