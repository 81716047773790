import React, { useState } from "react";
import styled from "styled-components";

import AutocompleteInput from ".";
import Spacings from "../Spacings";
import Text from "../Text";

const CssReset = styled(Spacings.Stack)`
  & ul {
    margin-left: 0 !important;
  }
`;

type Item = { id: string; label: string };
const items = [
  { id: "apple", label: "Apple" },
  { id: "banana", label: "Banana" },
  { id: "coconut", label: "Coconut" },
];

export const SingleSelectExample: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  return (
    <CssReset>
      <label htmlFor="single-select">
        <Text>Select a fruit</Text>
      </label>
      <AutocompleteInput.SingleSelect
        clearInputValueOnSelection
        id="single-select"
        onSelect={(item) => {
          setSelectedItem(item);
        }}
        renderItem={(item) => item.label}
        selectedItem={selectedItem}
        visibleItems={(downshift) =>
          downshift.inputValue === ""
            ? items
            : items.filter((item) =>
                item.label
                  .toLowerCase()
                  .includes((downshift.inputValue || "").toLowerCase()),
              )
        }
      />
    </CssReset>
  );
};

export const MultiSelectExample: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  return (
    <CssReset>
      <label htmlFor="multi-select">
        <Text>Select multiple fruits</Text>
      </label>
      <AutocompleteInput.MultiSelect
        id="multi-select"
        onSelectionChange={(itemList) => {
          setSelectedItems(itemList);
        }}
        renderItem={(item) => item.label}
        selectedItems={selectedItems}
        visibleItems={(downshift) =>
          downshift.inputValue === ""
            ? items
            : items.filter((item) =>
                item.label
                  .toLowerCase()
                  .includes((downshift.inputValue || "").toLowerCase()),
              )
        }
      />
    </CssReset>
  );
};
