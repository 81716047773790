import React from "react";
import styled from "styled-components";

import {
  colorByPriority,
  IconColor,
  IconPrority,
} from "../Icons/useMappedProps";

const Circle = styled.div<{
  color?: IconColor;
  priority: IconPrority;
}>`
  display: inline-block;
  border: ${(props) => props.theme.borders.width.medium}px solid
    ${(props) => colorByPriority[props.priority](props.theme, props.color)};
  padding: ${(props) => props.theme.spacings.medium};
  border-radius: 50%;
`;

const IconCircle: React.FC<
  React.PropsWithChildren<{
    color?: IconColor;
    priority?: IconPrority;
    children: React.ReactNode;
  }>
> = ({ priority = "primary", color = "gray", children }) => (
  <Circle priority={priority} color={color}>
    {children}
  </Circle>
);

export default IconCircle;
