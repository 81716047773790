import React from "react";
import styled from "styled-components";

import Step from "./Step";

const Steps = styled.ol`
  display: flex;
  list-style: none;
`;

export type GuidanceStep = {
  id: string;
  title: React.ReactNode;
  hasErrors?: boolean;
};

type GuidanceStepperProps = {
  steps: GuidanceStep[];
  activeStepId?: string;
  onStepChange?: (stepId: string) => void;
} & React.PropsWithoutRef<JSX.IntrinsicElements["ol"]>;

const GuidanceStepper: React.FC<
  React.PropsWithChildren<GuidanceStepperProps>
> = ({ steps, activeStepId, onStepChange, ...restStepsProps }) => {
  const activeStepIndex = steps.findIndex((step) => step.id === activeStepId);

  return (
    <Steps {...restStepsProps}>
      {steps.map((step, stepIndex) => (
        <Step
          key={step.id}
          isActive={stepIndex === activeStepIndex}
          isChecked={stepIndex < activeStepIndex}
          hasErrors={step.hasErrors}
          number={stepIndex + 1}
          title={step.title}
          onClick={onStepChange ? () => onStepChange(step.id) : undefined}
        />
      ))}
    </Steps>
  );
};

export default GuidanceStepper;
