import React from "react";
import styled, { css } from "styled-components";

import Card from "../../Card";
import Divider from "../../Divider";
import Markdown from "../../Markdown";
import Spacings from "../../Spacings";
import Text from "../../Text";

type StyledCardProps = {
  shadow?: boolean;
};

const StyledCard = styled(Card)<StyledCardProps>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    !props.shadow &&
    css`
      box-shadow: none;
    `};
`;

const StyledDivider = styled(Divider)`
  margin-top: auto;
`;

const StyledSpacingsInsetSquish = styled(Spacings.InsetSquish)`
  text-align: right;
`;

type ProductTypeCardProps = {
  button: React.ReactNode;
  children?: React.ReactNode;
  description?: string;
  name: string;
  shadow?: boolean;
};

const ProductTypeCard: React.FC<
  React.PropsWithChildren<ProductTypeCardProps>
> = ({ button, children, description, name, shadow = true }) => (
  <StyledCard shadow={shadow}>
    <Spacings.Inset scale="big">
      <Spacings.Stack scale="big">
        <Text textStyle="headline5">{name}</Text>
        {description ? (
          <Markdown priority="secondary" showListIcon>
            {description}
          </Markdown>
        ) : null}
      </Spacings.Stack>
    </Spacings.Inset>
    {children}
    <StyledDivider priority="secondary" />
    <StyledSpacingsInsetSquish scale="medium">
      {button}
    </StyledSpacingsInsetSquish>
  </StyledCard>
);

export default ProductTypeCard;
