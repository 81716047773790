import React from "react";
import styled from "styled-components";

import InfoIcon from "../Icons/InfoIcon";
import Spacings from "../Spacings";
import Text, { TextProps } from "../Text";

const StyledInset = styled(Spacings.Inset)`
  background-color: ${(props) => props.theme.palette.brand[100]};
  border-radius: ${(props) => props.theme.borders.radius.small}px;
`;

const StyledIcon = styled(InfoIcon)`
  margin-left: -${(props) => props.theme.spacings.small};
  color: ${(props) => props.theme.palette.brand.dark};
`;

const StyledText = styled(Text)`
  line-height: ${(props) => props.theme.spacings.huge};
`;

type PlayfulFormDescriptionProps = TextProps &
  React.PropsWithoutRef<JSX.IntrinsicElements["p"]> & { headline?: string };

const PlayfulFormDescription: React.FC<
  React.PropsWithChildren<PlayfulFormDescriptionProps>
> = ({ className, headline, ...restProps }) => (
  <StyledInset scale="big" className={className}>
    <Spacings.Inline scale="small" alignItems="flex-start">
      {headline ? (
        <span>
          <StyledIcon scale="huge" />
        </span>
      ) : null}
      <Spacings.Stack scale="small">
        {headline ? (
          <StyledText textStyle="bodyProlonged" isUncropped>
            {headline}
          </StyledText>
        ) : null}
        <Text priority="secondary" textStyle="bodyProlonged" {...restProps} />
      </Spacings.Stack>
    </Spacings.Inline>
  </StyledInset>
);

export default PlayfulFormDescription;
