import isSameDay from "date-fns/isSameDay";
import * as React from "react";
import styled from "styled-components";

import Calendar from "../Calendar";
import ResponsiveSwitcher from "../ResponsiveSwitch";
import Spacings from "../Spacings";
import TimeSlotPicker, { TimeSlot } from "../TimeSlotPicker";

const Container = styled.div`
  display: -ms-grid;
  display: grid;

  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;

  gap: ${(props) => props.theme.spacings.small};

  max-height: 287px;
`;

const Grid = styled(Spacings.Stack)`
  max-height: 287px;
  overflow-y: auto;

  /* WebKit-based browsers scrollbar customization */
  ::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.palette.gray[100]};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.palette.gray[300]};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.palette.gray[400]};
  }
`;

type DateTimePickerProps = Omit<
  React.ComponentProps<typeof Calendar>,
  "selectedDate" | "onDateSelect" | "enabledDates"
> & {
  availableTimeSlots: TimeSlot[];
  onTimeSlotSelect: (timeSlot: TimeSlot) => void;
  selectedTimeSlot?: TimeSlot;
};

const DateTimePicker: React.FC<
  React.PropsWithChildren<DateTimePickerProps>
> = ({
  availableTimeSlots,
  onTimeSlotSelect,
  selectedTimeSlot,
  ...calendarProps
}) => {
  const timeSlots = availableTimeSlots.sort(
    (a, b) => a.start.getTime() - b.start.getTime(),
  );
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    timeSlots[0]?.start,
  );

  return (
    <ResponsiveSwitcher
      mobile={<Spacings.Stack scale="tiny" />}
      tablet={<Container />}
      desktop={<Container />}
    >
      <Calendar
        selectedDate={selectedDate}
        onDateSelect={setSelectedDate}
        enabledDates={timeSlots.map((timeSlot) => timeSlot.start)}
        {...calendarProps}
      />

      <Spacings.Stack>
        <Grid>
          <Spacings.InsetSquish scale="tiny">
            <Spacings.Stack>
              <TimeSlotPicker
                timeSlots={
                  selectedDate
                    ? availableTimeSlots.filter((timeSlot) =>
                        isSameDay(selectedDate, timeSlot.start),
                      )
                    : []
                }
                selectedTimeSlot={selectedTimeSlot || null}
                shouldDisplayEndTime={true}
                onTimeSlotSelect={onTimeSlotSelect}
              />
            </Spacings.Stack>
          </Spacings.InsetSquish>
        </Grid>
      </Spacings.Stack>
    </ResponsiveSwitcher>
  );
};

export default DateTimePicker;
